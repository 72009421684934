import React, {useEffect} from 'react';
import Header from "./header/Header";
import Footer from "./footer/Footer";

function About() {

    useEffect(() => {
        document.title = 'À Propos | Aavon-IT';
      }, []);

  return (
    <>
    <Header/>

        <section className="page-section about-heading">
        <div className="container">
            <img className="img-fluid rounded about-heading-img mb-3 mb-lg-0" src="about.jpg" alt="..." />
            <div className="about-heading-content">
                <div className="row">
                    <div className="col-xl-9 col-lg-10 mx-auto">
                        <div className="bg-faded rounded p-5">
                            <h2 className="section-heading mb-4">
                                <span className="section-heading-lower">À propos de nous!</span>
                            </h2>
                            <p>Chez <strong>Aavon-IT</strong>, nous sommes animés par la passion de la technologie et l'engagement envers l'excellence.Nous offrons un large éventail de services pour répondre aux besoins technologiques des entreprises modernes.</p>
                            <p className="mb-3"><strong>Notre Mission</strong> : Notre mission est de fournir des solutions technologiques innovantes et fiables qui aident nos clients à transformer et à faire évoluer leurs opérations commerciales. Nous nous efforçons de créer des produits et services qui non seulement répondent aux attentes de nos clients mais les dépassent, en nous concentrant sur la qualité, l'efficacité et la valeur ajoutée.</p>
                            <p><strong>Notre Équipe</strong> : Notre force réside dans notre équipe d'experts, composée de développeurs talentueux, d'ingénieurs expérimentés, de consultants en TI et de spécialistes en marketing digital. Nous sommes fiers de notre culture d'entreprise qui encourage l'innovation, la collaboration et l'apprentissage continu.</p>
                            <p></p>
                        </div>
                        <div className="intro-button mx-auto intro_custom_btn"><a className="btn btn-primary btn-xl" href="/contact-us">Contactez-Nous!</a></div>
                    </div>
                </div>
            </div>
        </div>
        </section>

    <Footer/>
    </>
  )
}

export default About