import React, {useState, useEffect} from 'react';
import Header from "./header/Header";
import Footer from "./footer/Footer";
import '../style/contact.css';

function Contact() {

    useEffect(() => {
        document.title = 'Contact | Aavon-IT';
      }, []);

    const [formState, setFormState] = useState({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    // const changeHandler = (event) =>{
    //     setFormState({ ...formState, [event.target.name]: event.target.value });
    // }


    const submitHandler = (event) =>{
        event.preventDefault();
        const config = {
            SecureToken : "b817aee1-424c-4c22-a880-3f3d96553d34",
            To : 'contact@aavon-it.com',
            From : formState.email,
            Subject : "Contact depuis Aavon-IT",
            Body : `${formState.name} : ${formState.description}`
        };

        if(window.Email){
            window.Email.send(config).then(() =>{
                 console.log("Email sent");

                 setIsFormSubmitted(true);

             setTimeout(() => {
                setIsFormSubmitted(false);
                setFormState({ name: '', email: '', description: '' });
            }, 5000);

                });
        }
    }

  return (
    <>
    <Header/>
    <section className="page-section_contact cta cta_contact">
        <div className="container">
            <div className="row">
                <div className="col-xl-9 mx-auto">
                    <div className="cta-inner bg-faded text-center rounded">
                        <h2 className="section-heading mb-5">
                            <span className="section-heading-upper">Aavon-it</span>
                            <span className="section-heading-lower">Contacter-nous</span>
                        </h2>
                        <form className="contact-form" onSubmit={submitHandler}>
                            <div className="input-box">
                                <input type="text" placeholder="Nom" 
                                name="name"  
                                value={formState.name}
                                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                                required
                                 />
                            </div>
                            <div className="input-box">
                                <input type="email" placeholder="E-mail"
                                name="email" 
                                value={formState.email}
                                onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                                required
                                />
                            </div>
                            <div className="input-box message-box">
                                <input type="textarea" placeholder="Description..." 
                                name="description" 
                                value={formState.description}
                                onChange={(e) => setFormState({ ...formState, description: e.target.value })}
                                required
                                />
                            </div>
                            <div className="button cotact_form_btn">
                                <input type="submit" value="Envoyer" className="btn cotact_btn"/>
                            </div>
                        </form>
                        {isFormSubmitted && (
                            <div className="success-message-contact">
                            Votre message a été envoyé avec succès.
                            </div>
                        )}
                        <p className="address mb-1 mt-5">
                            <em>
                                <strong>E-mail </strong> : contact@aavon-it.com
                                <br/>
                                <strong>Tel </strong> : (+33) 7 44 18 07 44 / (+212) 777 39 64 57
                            </em>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
</>
  )
}

export default Contact