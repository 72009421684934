import React, {useEffect} from 'react';
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Link } from 'react-router-dom';

function Home() {

    useEffect(() => {
        document.title = 'Accueil | Aavon-IT';
      }, []);

  return (
    <>
    <Header/>
        <section className="page-section clearfix">
            <div className="container">
                <div className="intro">
                    <img className="intro-img img-fluid mb-3 mb-lg-0 rounded" src="tech_web.jpeg" alt="..." />
                    <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                        <h2 className="section-heading mb-4">
                            <span className="section-heading-upper">Aavon-IT</span>
                            <span className="section-heading-lower">Notre Mission</span>
                        </h2>
                        <p className="mb-3">Notre équipe composée d’experts techniques et fonctionnels, ont développé une maîtrise de l’ensemble des métiers associés à la mise en place de nos <Link to="/services">services</Link>.<br/>Notre mission est de fournir des solutions technologiques innovantes et fiables qui répondent aux attentes de nos clients.</p>
                        <div className="intro-button mx-auto"><a className="btn btn-primary btn-xl" href="/contact-us">Contactez-Nous!</a></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="page-section cta">
            <div className="container">
                <div className="row">
                    <div className="col-xl-9 mx-auto">
                        <div className="cta-inner bg-white text-center rounded">
                            <h2 className="section-heading mb-4">
                                <span className="section-heading-upper">Aavon-IT</span>
                                <span className="section-heading-lower">Nos Valeurs</span>
                            </h2>
                            <p className="mb-0 text-left">

                            <div className='row'><div className='col-lg-2 col-sm-6'><strong>Qualité :</strong></div> <div className='col-lg-10 col-sm-6'>Chaque projet est une opportunité pour nous de démontrer notre engagement envers l'excellence.
                            </div></div>

                            <div className='row'><div className='col-lg-2 col-sm-6'><strong>Intégrité :</strong></div><div className='col-lg-10 col-sm-6'> Nous agissons avec honnêteté et transparence envers nos clients et partenaires.
                            </div></div><br/>

                            <div className='row'><div className='col-lg-2 col-sm-6'><strong>Collaboration :</strong></div><div className='col-lg-10 col-sm-6'> Nous croyons au pouvoir du travail d'équipe et à la collaboration étroite avec nos clients pour atteindre les meilleurs résultats.
                            </div></div>
                            
                            <div className='row'><div className='col-lg-2 col-sm-6'><strong> Notre Engagement :</strong></div><div className='col-lg-10 col-sm-6'> Chez Aavon-IT, nous sommes dédiés à aider nos clients à naviguer dans le paysage technologique en constante évolution. Nous sommes engagés à fournir des solutions personnalisées qui non seulement répondent aux défis actuels mais anticipent également les besoins futurs.
                            </div></div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Footer/>
    </>
  )
}

export default Home