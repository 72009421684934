import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark py-lg-4" id="mainNav">
        <Link className='logo' to='/'>
            <img className="logo_aavon" src="aavon-it_logo.png" alt="Aavon-IT..." />
        </Link>
            <div className="container">
                <a className="navbar-brand text-uppercase fw-bold d-lg-none" href="/">Aavon-IT</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item px-lg-4"><a className="nav-link text-uppercase" href="/">Accueil</a></li>
                        <li className="nav-item px-lg-4"><a className="nav-link text-uppercase" href="/about-us">À Propos</a></li>
                        <li className="nav-item px-lg-4"><a className="nav-link text-uppercase" href="/services">Services</a></li>
                        <li className="nav-item px-lg-4"><a className="nav-link text-uppercase" href="/contact-us">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
  )
}

export default Header