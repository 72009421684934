import './style/styles.css';
import Home from './commponents/Home';
import Services from './commponents/Services';
import Contact from './commponents/Contact';
import About from './commponents/About';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

  return (
    <BrowserRouter  basename="/">
    <Routes>
          <Route exact path="/" element={<Home />}/>

          <Route path='/about-us' element={<About />}/>
          <Route path='/services' element={<Services />}/>
          <Route exact path='/contact-us' element={<Contact />}/>

          <Route path="*" element={<Home />}/>   
          
    </Routes>
</BrowserRouter>
  );
}

export default App;

