import React from 'react';

function Footer() {
  return (
    <footer className="footer text-white text-center py-5">
        <div className="container"><p className="m-0 small">Copyright &copy; Aavon-IT 2023</p></div>
    </footer>
  )
}

export default Footer