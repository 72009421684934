import React, {useEffect} from 'react';
import Header from "./header/Header";
import Footer from "./footer/Footer";

function Services() {

    useEffect(() => {
        document.title = 'Services | Aavon-IT';
      }, []);

  return (
    <>
    <Header/>

        <section className="page-section">
            <div className="container">
                <div className="product-item">
                    <div className="product-item-title d-flex">
                        <div className="bg-faded p-5 d-block ms-auto rounded">
                            <h2 className="section-heading mb-1">
                                <span className="section-heading-upper">Développement de sites web</span>
                            </h2>
                            <p className="mb-0 mw-450">Création de sites web personnalisés, y compris des sites e-commerce, des blogs, des portfolios, etc.</p>

                        </div>
                    </div>
                    <img className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src="logiciels-de-création-de-sites-Web.webp" alt="..." />
                    <div className="product-item-description d-flex me-auto">
                        <div className="bg-faded p-5 rounded">
                        <h2 className="section-heading mb-1">
                        <span className="section-heading-upper">Développement d'applications mobiles</span>
                            </h2>
                            <p className="mb-0 mw-450">
                            Conception et développement d'applications pour iOS, Android et toutes autres plateformes mobiles.
                            </p>
                            </div>
                    </div>

                    <div className="product-item-description d-flex ms-auto pt-3">
                        <div className="bg-faded p-5 rounded">
                        <h2 className="section-heading mb-1">
                                <span className="section-heading-upper"> Développement d'applications web</span>
                            </h2>
                            Solutions web sur mesure pour les entreprises, incluant des applications SaaS, des outils internes, etc.
                            <p className="mb-0 mw-450"> </p></div>
                    </div>


                </div>
            </div>
        </section>
        <section className="page-section">
            <div className="container">
                <div className="product-item">
                    <div className="product-item-title d-flex">
                        <div className="bg-faded p-5 d-block me-auto rounded">
                            <h2 className="section-heading mb-0">
                                <span className="section-heading-upper">Sécurité informatique</span>
                            </h2>
                            <p className="mb-0 mw-450">Solutions de cybersécurité pour protéger les données et les infrastructures informatiques contre les menaces en ligne. </p>

                        </div>
                    </div>
                    <img className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src="creation_web.jpeg" alt="..." />
                    <div className="product-item-description d-flex ms-auto">
                        <div className="bg-faded p-5 rounded">
                        <h2 className="section-heading mb-1">
                                <span className="section-heading-upper">Intégrations ERP</span>
                            </h2>
                            <p className="mb-0 mw-450"> Mise en place et intégration de systèmes ERP Odoo pour optimiser les processus d'affaires et la gestion des ressources. Customisation répondant aux besoins du client.
</p></div>
                    </div>
                </div>
            </div>
        </section>
    <Footer/>
    </>
  )
}

export default Services